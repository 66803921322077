<template>
    <div class="row">
      <div class="col-md-12">
        <div class="row py-3 px-4" id="filter-card">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row justify-content-start items-baseline w-100 mb-2">
          <span
              @click="$router.go(-1)"
              class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2">
            <i class="fa fa-chevron-left"></i>
          </span>
              <h3 class="d-inline-block" id="page-title">{{ $t('menu.management.invoice_custom_approval_users').toUpperCase() }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <dashboard-box :back="true" class="px-5 py-4">
          <template v-slot:preview>
            <div class="w-100 row mb-7" v-if="form">
                <div class="col-12">
                    <span class="cursor-pointer" @click="addOptionalOptions()" v-html="getIconByKey('icons.model_house.add', {
                    class: 'w-20px h-20px d-inline-block object-cover'
                    })"></span>
                </div>
                <div class="col-12">
                  <div class="row" v-for="(item,index) in form" :key="index" >
                      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                          <custom-multi-select
                              :model.sync="item.user_id"
                              :options="users"
                              :title="$t('general.users')"
                              :itemPerRow="1"
                              name="users"
                              :multiple="false"
                              :is-inline="false"
                              :not-list="true"
                              :max="1"
                          />
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                          <custom-multi-select
                              :disabled="item.id  || item.approved_status_id === 32"
                              :model.sync="item.approved_status_id"
                              :options="statuses"
                              :title="$t('custom_invoice_approval_types.approval_status')"
                              :itemPerRow="1"
                              name="approved"
                              :multiple="false"
                              :is-inline="false"
                              :not-list="true"
                              :max="1"
                          />
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                          <custom-multi-select
                              :disabled="item.id?true:false"
                              :model.sync="item.reject_status_id"
                              :options="statuses"
                              :title="$t('custom_invoice_approval_types.reject_status')"
                              :itemPerRow="1"
                              name="rejects"
                              :multiple="false"
                              :is-inline="false"
                              :not-list="true"
                              :max="1"
                          />
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                        <text-input
                        :disabled="true"
                            :helper-text="$t('general.sort')"
                            :model.sync="item.sort"
                            :title="$t('general.sort')"
                        ></text-input>
                      </div>
                      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12">
                        <span class="cursor-pointer" v-if="isUserGranted('InvoiceCustomApprovalUser', ['delete'],false,true)" @click="deleteItem(index)" v-html="getIconByKey('icons.sample.delete_red',{
                            class: 'w-25px h-25px d-inline-block mr-3 '
                          })">
                        </span>
                      </div>

                  </div>
                </div>
              
            </div>
            <div class="d-flex justify-content-center">
              <button-with-icon
                  class="mr-3 danger-border-1px"
                  @onClick="$router.go(-1)"
                  :text="$t('general.cancel')"
                  size="lg"
                  :icon-name="null"
              ></button-with-icon>
              <button-with-icon
                  @onClick="createOrUpdate"
                  :text="$t(id != null ? 'general.save' : 'general.create')"
                  size="lg"
                  :icon-name="null"
                  :disabled="!isValidToSubmit"
              ></button-with-icon>
            </div>
          </template>
        </dashboard-box>
      </div>
    </div>
  </template>
  
  <script>
  import DashboardBox from "@/assets/components/DashboardBox";
  import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
  import TextInput from "@/assets/components/inputs/TextInput";
  import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
  import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
  import store from "@/core/services/store";
  import _ from "lodash";
  import router from "@/router";
  import {
    BASE_URL,
    ERROR,
    LOADING,
    SUCCESS,
    ITEMS,
    CREATE_ITEM,
    GET_ITEMS,
    UPDATE_ITEM_BY_ID
  } from "@/core/services/store/invoice-custom-approval-users/invoice_custom_approval_users.module";

  import userModule, {
  BASE_URL as BASE_URL_USER,
  MODULE_NAME as MODULE_NAME_USER,
  GET_ITEMS as GET_ITEMS_USER,
  ITEMS as ITEMS_USER,
} from "@/core/services/store/user/user.module";



  const _MODULE_NAME_USER = MODULE_NAME_USER;

  export default {
    name: "InvoiceCustomApprovalUsersCreateEdit",
    components: {
      ButtonWithIcon,
      TextInput,
      DashboardBox,
      CustomMultiSelect,
      TextInput
    },
    beforeCreate() {
      function registerStoreModule(moduleName, storeModule) {
        if (!(store && store.state && store.state[moduleName])) {
          store.registerModule(moduleName, storeModule)
        }
      }
      registerStoreModule(_MODULE_NAME_USER, userModule)
    },
    data() {
      return {
        id: this.$route.params.id,
        indexRouteName: 'management.invoice-custom-approval-users.index',
        form:[],
        statuses:[]
      };
    },
    computed: {
      error() {
        return store.getters[ERROR];
      },
      loading() {
        return store.getters[LOADING];
      },
      success() {
        return store.getters[SUCCESS];
      },
      users() {
        let items = store.getters[_MODULE_NAME_USER + '/' + ITEMS_USER];
        if (items) {
            return this.convertArrayToObjectByKey(items, 'id', 'name');
        }
        return {};
      },
      isValidToSubmit() {
        let result=true
        this.form.forEach(item=>{
          if(item.sort==='0'){
            this.sweetAlertError(this.$t('csr.validations.join_life'));
            result=false;
          }
          if(!+item.user_id)result=false;
          if(!+item.approved_status_id)result=false;
          if(!+item.reject_status_id)result=false;
          if(item.sort==null||item.sort==''||item.sort==undefined)result=false;
        })
        return result;
      },
      
    },
    methods: {
      addOptionalOptions(){
        let lastItem = _.cloneDeep(this.form[this.form.length - 1]);
        let lastSort =this.form.length>0?lastItem.sort:0;
        let approve_id =this.form.length>0?0:32;
        this.form.push({
            user_id:0,
            reject_status_id:0,
            approved_status_id:approve_id,
            sort:lastSort+1
        }
        )
      },
      createOrUpdate() {
        let self=this;
        let payload = {
          url: this.id == null ? BASE_URL : BASE_URL + '/' + this.id,
          contents: this.form,
        }
        this.$store.dispatch(this.id == null ? CREATE_ITEM : UPDATE_ITEM_BY_ID, payload).then(result=>{
          if(result.status){
            self.sweetAlertSuccess(self.$t('general.successfully_created'));
            self.$router.go(-1)
          }
        });
      },
      getUsers() {
        this.$store.dispatch(_MODULE_NAME_USER + '/' + GET_ITEMS_USER, {
            url: BASE_URL_USER
        });
      },
      getInvoiceCustomApprovalUsers(){
        let payload = {
          url:BASE_URL,
          filters: {
              sort:'asc',
              order_by:'sort'
          }
        }
        this.$store.dispatch( GET_ITEMS,payload ).then(result=>{
          if(result.status){
            let data=result.data;
            this.form=data.data
          }
        });
      },
      deleteItem(index) {
        this.sweetAlertConfirm(this.$t("general.are_you_sure")).then(result => {
          if(this.form[index].id){
            this.sweetAlertError(this.$t("general.cant_deleted_record"))
          }else{
            this.form.splice(index, 1);
          }
          
        })
        
      },
      getCustomApprovalStauses() {
            this.$store.dispatch(GET_ITEMS, {
                url: 'api/statuses',
                filters: {
                    type_id: 6
                },
            }).then(result => {
                if (result.status) {
                    let data = result.data.filter(item => item.id !== 33);
                    this.statuses = this.convertArrayToObjectByKey(data, 'id', 'translations.0.name')
                }
            });
        }
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: this.$t("general.dashboard"), route: "dashboard"},
        {title: this.$t("menu.management.invoice_custom_approval_users"), route: this.indexRouteName},
        {title: this.$t(this.id ? "custom_invoice_approval_users.create_custom_invoice_approval_users" : "custom_invoice_approval_users.edit_custom_invoice_approval_users")},
      ]);

      this.getCustomApprovalStauses();
      this.getUsers();
      this.getInvoiceCustomApprovalUsers();
    }
  }
  </script>
  
  <style scoped>
  #dashboard-box {
    padding: 10px;
  }
  </style>
  